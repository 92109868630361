<template>
    <div class="test-drive-sign">
        <div class="title">{{ $t('试乘试驾信息登记') }}</div>
        <van-form ref="myForm" class="labelTop" label-width="6.2em" > 
            <van-field v-model.trim="formData.name" :label="$t('姓名')" :rules="nameRules"/>
            <van-field v-model.trim="formData.idCardNum" :label="$t('身份证号')" :rules="idRules"/>
        </van-form>

        <div class="tips">
            <span>为了向您提供更顺畅的试乘试驾服务，我们会通过ID. UNYX 专家征求您在到店后与我们交流的</span>
            <span class="yellow-text">语音信息</span>
            <span>和</span>
            <span class="yellow-text">试乘试驾时行车路线</span>
            <span>的同意，以优化您的服务体验并向您展示数字化报告。</span>
            <span class="yellow-text">您若不勾选同意，亦不影响签署《试乘试驾协议》后享受的基础试乘试驾服务</span>
        </div>

        <div class="agreement">
            <van-checkbox v-model="formData.personalInfoCheckStatus" shape="square" icon-size="16px">
                <span>我已阅读并同意</span>
                <span class="yellow-text" @click="isShow=true">《试乘试驾个人信息保护声明》</span>
            </van-checkbox>
        </div>
        <van-popup v-model="isShow" position="bottom" class="agreement-popup">
            <div v-if="isShow" class="agreement-info">
                <div class="header">试乘试驾个人信息保护声明</div>
                <div class="sub-header">
                    本声明适用于大众汽车（安徽）数字化销售服务有限公司（以下简称“我们”）为您提供的试乘、试驾服务，您在接受试乘、试驾服务前，请仔细阅读本声明（特别是黑体加粗条款），以便您充分知晓本声明的目的、内容和您行使相关权利的途径与方式。
                    <span class="bold underline">您的阅读及勾选动作即代表您已充分了解我们提供的试乘试驾服务，已知晓并同意我们将通过相关设备对您试乘试驾过程中的个人信息进行处理。</span>
                </div>
                <ol class="content">
                    <li>出于提供试乘/试驾服务、遵守法律法规，以及查验试乘/试驾人身份的需要，大众服务人员将代表我们收集您的【姓名、联系方式、
                        <span class="bold">身份证号码和机动车驾驶证信息</span>
                        】。
                    </li>
                    <li>
                        出于提升试乘试驾服务质量和安全能力，并为实现向您提供数字化的试乘试驾报告的需要，在征求您的明确同意后，处理此次试乘试驾过程中的<span class="bold">音频</span>信息、<span class="bold">车辆地理位置</span>信息，以及其他用于本次试乘试驾的车辆数据（包括试乘试驾车载信息数据和车辆性能等数据）。
                    </li>
                    <li>我们会采取符合业界标准的安全措施，对您的个人信息进行去标识化或匿名化的处理，且以不会与您识别的方式进行统计分析，或向您提供您可能感兴趣的相关用户活动信息，但请您放心，如您不愿接收这些信息，您可以通过活动信息内容中提供的退订方式进行退订，也可以直接与我们联系以进一步处理。</li>
                    <li>基于同意处理个人信息时，您有权选择同意或不同意我们处理您的上述个人信息。请您知悉，您同意我们处理的上述数据仅在本次试驾场景中与您个人相关。实现上述目的后，我们将采取符合法律法规的业界安全防护标准，对您的个人信息进行匿名化处理，不会对您的个人信息权益造成不当影响。</li>
                    <li>我们仅在本声明所述目的所必需的期限内保存您的个人信息，除非您另行授权同意或法律法规有强制的保存期限要求。超出上述个人信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。</li>
                    <li>如您需查阅、复制、转移、更正、补充、删除个人信息，或您有任何疑问，您可拨打客服电话<span class="bold">【400-023-4567】</span>或通过<span class="bold">【官方网站、APP等渠道】</span>联系我们，我们将在验证您的身份后于<span class="bold">【15】</span>个工作日答复您的请求，特别的，如您需要删除您的敏感个人信息（适用时），我们将在验证您身份后于<span class="bold">【10】</span>个工作日答复您的请求。</li>
                    <li>本申明中尚未提及的其他事项，将适用《大众ID.UX隐私政策》。</li>
                </ol>
                <div class="bold">您理解并同意，本声明自您阅读并勾选同意后生效。</div>
                <div class="btn" @click="onFinishRead">我已阅读并同意</div>
            </div>
        </van-popup>

        <div class="footer" @click="onSign">
            <div>签署协议</div>
        </div>
    </div>
</template>
<script>
import FormVue from '@/modules/components/form/index.vue'
import testDriveServices from '@/services/testDrive.js'
import loading from '@/utils/loading'
import { testDriveDetail } from '@/services/appointment.js'

export default {
  name: 'test-drive-sign',
  components: { FormVue },
  data() {
    return {
      formData:{
        idCardNum: '',
        name: '',
        param: '',
        personalInfoCheckStatus: false,
      },
      isShow: false,
      nameRules: [
        { required: true, message: '请输入姓名' },
        { pattern: /^[\s\S]{0,20}$/, message: '姓名不能超过20个字符' }
      ],
      idRules: [
        { required: true, message: '请输入身份证号码' },
        { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '身份证号码格式不正确' }
      ],
      contractSignUrl: ''
    }
  },
  mounted(){
    this.formData.param = this.$route.query.param
    testDriveDetail(this.formData.param).then(res=>{
      this.contractSignUrl = res.contractSignUrl
    })
  },
  methods: {
    onFinishRead(){
      this.formData.personalInfoCheckStatus = true
      this.isShow = false
    },
    async onSign(){
      if (this.contractSignUrl){
        this.$toast('已签署合同，请勿重复签署')
        return
      }
      // 校验
      this.$refs.myForm.validate().then(async ()=>{
        // 提交
        loading.showLoading()
        const params = {
          ...this.formData,
          personalInfoCheckStatus: this.formData.personalInfoCheckStatus ? 1 : 0
        }
        testDriveServices.testDriveInfoSign(params).then(res=>{
          window.location.href = res
        }).finally(()=>{
          loading.hideLoading()
        })
        
      })
    }
  }
}
</script>
<style lang="less" scoped>
.test-drive-sign {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 16px;
    background-color: @black !important;
    .title{
        font-size: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        color: @yellow-text;
    }
    /deep/.labelTop{
        .van-field{
            display: block;
        }
        .van-cell{
            background-color: @black;
            margin-bottom: 20px;
        }
        .van-field__label,.van-field__control{
            color: #fff;
        }
        .van-cell::after{
            border-bottom: 0 !important;
        }

        .van-field__body{
            border-bottom: 1px solid #ebedf0;
        }
    }
    .tips,.agreement{
        font-size: 14px;
        color: #fff;
        margin-top: 68px;
        .yellow-text{
            color: @yellow-text;
        }
        /deep/.van-checkbox__label{
            font-size: 14px;
            color: #fff;
        }
    }

    .agreement-popup{
        width: 100%;
        height: 60%;
        max-width: 500px;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px;
    }

    .agreement-info{
        font-size: 14px;
        color: gray;
        .header{
            font-size: 18px;
            text-align: center;
            margin-bottom: 20px;
        }
        .sub-header{
            margin-bottom: 20px;
        }
        .underline{
            text-decoration: underline;
        }
        .bold{
            font-weight: 600;
            color: @black;
        }
        ol{
            padding-left: 20px;
            list-style-type: decimal;
        }
        ol li {
            margin-bottom: 15px;
        }
        .btn{
            width: 100%;
            height: 48px;
            border-radius: 10px;
            margin-top: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @black;
            font-size: 16px;
            background-color: @yellow-text;
            cursor: pointer;
        }
    }

    .footer{
        // position: absolute;
        // bottom: 22px;
        // left: 50%;
        // transform: translateX(-50%);
        // width: 80%;
        margin-top: 100px;
        height: 48px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @black;
        font-size: 16px;
        background-color: @yellow-text;
        cursor: pointer;
    }
}
</style>